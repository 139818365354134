<template>
  <div class="content">
    <!-- Page title -->
    <div class="container-fluid">
      <div class="page-header d-print-none">
        <div class="row align-items-center">
          <div class="col">
            <h2 class="page-title">Master Item Directory</h2>
          </div>
          <div class="col-auto ml-auto"></div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-auto">
              <div class="input-icon mb-3">
                <input
                  type="text"
                  class="form-control"
                  v-model="search"
                  placeholder="Search..."
                  required
                />
              </div>
            </div>
            <div class="col-auto">
              <div class="input-icon mb-3">
                <el-select v-model="selectedChannel" placeholder="Select">
                  <el-option
                    v-for="channel in channelOptions"
                    :key="channel.id"
                    :label="channel.name"
                    :value="channel.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="col-auto ml-auto mb-2">
              <el-button
                type="primary"
                size="medium"
                @click="$router.push('add-channel')"
              >Add New Channel</el-button>
              <upload-file />
            </div>
          </div>
          <data-table :items="items" @editItem="editItem"/>
          <div class="row align-items-center">
            <div class="col-auto ml-auto mt-4">
              <el-pagination
                v-if="pagination"
                background
                layout="prev, pager, next"
                :total="pagination.totalItems"
                :current-page.sync="currentPage"
                @current-change="handleTableChange"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="item">
      <item-form 
        :item_id='item_id' 
        :item="item" 
        :show.sync="editDrawer" 
        @refreshTable='refreshTable'
        @closeDrawer='editDrawer=false,item=null'/>
    </template>
  </div>
</template>
<script>
import UploadFile from "./components/UploadFile";
import DataTable from "./components/DataTable";
import ItemForm from "./components/ItemForm";
import { getMasterItemDirectory, getChannels } from "@/services/master-item-directory.js";
export default {
  name: "MasterItemDirectory",
  components: {
    UploadFile,
    DataTable,
    ItemForm
  },
  data() {
    return {
      filterVisible: false,
      viewVisible: false,
      uploadListingsCost: false,
      loading: false,
      items: [],
      pagination: null,
      search: "",
      editDrawer: false,
      item: null,
      currentPage: 1,
      item_id: null,
      channelOptions: [{name:'All',id:null}],
      selectedChannel: null
    };
  },
  watch: {
    search(val) {
      this.currentPage = 1
      this.fetch();
    },
    selectedChannel(val) {
      this.currentPage = 1
      this.fetch();
    },
  },
  methods: {
    refreshTable() {
      this.fetch();
    },
    fetch() {
      let params = {
        page: this.currentPage,
        query:this.search,
        channelId: this.selectedChannel
      }
      this.loading = true;
      getMasterItemDirectory({ ...params }).then(res => {
        this.loading = false;
        this.items = res.data.items;
        this.pagination = res.data.meta;
      });
    },
    handleTableChange(page) {
      this.fetch();
    },
    editItem(item) {
      this.editDrawer = true;
      this.item_id = item.id
      item.duration = null
      this.item = Object.assign({},item) 
      this.item.tag_duration =  this.item.tag?this.item.tag_duration:null
    },
  },
  mounted() {
    this.fetch();
    getChannels().then(res => {
      res.data.forEach( ch => {
        this.channelOptions.push(ch)
      })
      //this.selectedChannel = this.channelOptions[0]
    });
  }
};
</script>
<style scoped>
.tab-bar-extra {
  position: relative;
  top: -10px;
}
</style>