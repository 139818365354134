<template>
  <el-drawer
    title="Item Details"
    :visible.sync="showDrawer"
    :direction="direction"
    :before-close="handleClose"
    size="50%"
      >
      <template slot="title">
        <div class="row">
          <div class="col">
            <h3>Item Details</h3>
          </div>
          <div class="col-auto ml-auto">
            <el-button type="primary" @click="saveItem()" size="small">Save</el-button>
          </div>
        </div>
      </template>
    
      <el-form label-position="top" label-width="100px" :model="item" size="medium" ref="itemForm">
        <div class="container" v-loading='loading'>
          <div class="row">
            <div class="col">
              <el-form-item label="Parent ASIN" size="small">
                <el-input size="medium" v-model="item.parent_asin"></el-input>
              </el-form-item>
            </div>
            <div class="col">
              <el-form-item label="(Child) ASIN" size="small">
                <el-input size="medium" v-model="item.asin"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <el-form-item label="SKU" size="small">
                <el-input size="medium" v-model="item.sku"></el-input>
              </el-form-item>
            </div>
            <div class="col">
              <el-form-item label="Item Short Description" size="small">
                <el-input size="medium" v-model="item.short_description"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <el-form-item label="Overzied" size="small">
                <el-input size="medium" v-model="item.overzied"></el-input>
              </el-form-item>
            </div>
            <div class="col">
              <el-form-item label="Brand" size="small">
                <el-input size="medium" v-model="item.brand"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <el-form-item label="Cost Per Single Unit" size="small"
              prop='cost_per_single_unit'
              :rules="[{ validator: checkNumber, trigger: 'blur' }]">
                <el-input size="medium" v-model="item.cost_per_single_unit"></el-input>
              </el-form-item>
            </div>
            <div class="col">
              <el-form-item label="Units" size="small"
              prop='units'
              :rules="[{ validator: checkNumber, trigger: 'blur' }]">
                <el-input size="medium" v-model="item.units"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <el-form-item label="Bag/Packaging/Label/Etc." size="small">
                <el-input size="medium" v-model="item.packaging"></el-input>
              </el-form-item>
            </div>
            <div class="col">
              <el-form-item label="Estimated Labour Per Unit" size="small"
              prop='estimated_labour_per_unit'
              :rules="[{ validator: checkNumber, trigger: 'blur' }]">
                <el-input size="medium" v-model="item.estimated_labour_per_unit"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <el-form-item label="Shipping Cost" size="small"
              prop='shipping_cost'
              :rules="[{ validator: checkNumber, trigger: 'blur' }]">
                <el-input size="medium" v-model="item.shipping_cost"></el-input>
              </el-form-item>
            </div>
            <div class="col">
              <el-form-item label="Anticipated Sale Price" size="small"
              prop='anticipated_sale_price'
              :rules="[{ validator: checkNumber, trigger: 'blur' }]">
                <el-input size="medium" v-model="item.anticipated_sale_price"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <el-form-item label="Item Cost" size="small"
              prop='total_cost'
              :rules="[{ validator: checkNumber, trigger: 'blur' }]">
                <el-input size="medium" v-model="item.total_cost"></el-input>
              </el-form-item>
            </div>
            <div class="col">
              <el-form-item label="Units per case ordered" size="small"
              prop='units_per_case_ordered'
              :rules="[{ validator: checkNumber, trigger: 'blur' }]">
                <el-input size="medium" v-model="item.units_per_case_ordered"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <el-form-item label="Box Number" size="small"
              prop='box_number'
              :rules="[{ validator: checkNumber, trigger: 'blur' }]">
                <el-input size="medium" v-model="item.box_number"></el-input>
              </el-form-item>
            </div>
            <div class="col">
              <el-form-item label="Bag Color" size="small">
                <el-input size="medium" v-model="item.bag_color"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <el-form-item label="Referral Fee %" size="small"
              prop='referral_fee_percentage'
              :rules="[{ validator: checkNumber, trigger: 'blur' }]">
                <el-input size="medium" v-model="item.referral_fee_percentage"></el-input>
              </el-form-item>
            </div>
            <div class="col">
              <el-form-item label="Est Referral Fee $" size="small"
              prop='estimate_referral_fee'
              :rules="[{ validator: checkNumber, trigger: 'blur' }]">
                <el-input size="medium" v-model="item.estimate_referral_fee"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <el-form-item label="Est FBA Fee" size="small"
              prop='estimate_fba_fee'
              :rules="[{ validator: checkNumber, trigger: 'blur' }]">
                <el-input size="medium" v-model="item.estimate_fba_fee"></el-input>
              </el-form-item>
            </div>
            <div class="col">
              <el-form-item label="Item Status" size="small">
                <el-input size="medium" v-model="item.item_status"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <el-form-item size="small">
                <template slot="label">
                  <div class="row">
                    <div class="col-2">
                     Tag 
                    </div>
                    <div class="col-auto markdown" v-if="item.tag_duration">
                      <span class="text-green">Expires in : {{item.tag_duration}}</span>
                    </div>
                  </div>
                </template>
                <el-input size="medium" v-model="item.tag"></el-input>
              </el-form-item>
            </div>
            <div class="col">
              <el-form-item label="Tag Duration" size="small"
              prop='duration'
              :rules="[{ validator: checkNumber, trigger: 'blur' }]">
                <el-input size="medium" v-model="item.duration" :disabled='!item.tag'></el-input>
              </el-form-item>
            </div>
          </div>
        </div>
      </el-form>
  </el-drawer>
</template>

<script>
import { saveItem } from "@/services/master-item-directory.js";
export default {
  props: {
    item: Object,
    show: {
      type: Boolean,
      default: false,
    },
    item_id: String
  },
  data() {
    return {
      direction: "rtl",
      loading: false,
    }
  },
  computed: {
    showDrawer() {
      return this.show
    }
  },
  methods: {
    checkNumber(rule, value, callback) {
      const regex_integer = /^[0-9]+$/;
      const regex_float = /^\d*(\.\d+)?$/;
      if (value) {
        if (regex_integer.test(value) || regex_float.test(value)) {
          callback();
        } else {
          callback(new Error("Please input digits"));
        }
      } else {
        callback();
      }
    },
    handleClose(done) {
      this.$confirm("Are you sure you want to close this?")
      .then(_ => {
        done();
        this.$emit('closeDrawer')
      })
      .catch(_ => {
        
      });
    },
    saveItem() {
      this.$refs['itemForm'].validate((valid) => {
        if (valid) {
          this.submitForm()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getDifference(obj1, obj2) {
      let keyFound = {};
      Object.keys(obj1).forEach(key => {
          if(obj1[key] !== obj2[key]){
            return keyFound[key] = obj2[key];
          }
      });
      return keyFound || -1;
    },
    submitForm() {
      let newObject = this.getDifference(this.originalItem, this.item);
      try {
        this.loading = true
        if (newObject.duration) {
          newObject.tag_duration = this.item.duration
        }
        saveItem(this.item_id,newObject).then(res=> {
        this.loading = false
        if (res) {
          if (res.data.success) {
            this.$message({
              showClose: true,
              message: 'The item has been saved successfully',
              type: 'success'
            });
            this.$emit('refreshTable')
            this.$emit('closeDrawer')
          }
        }
        }).catch( e => {
          this.loading = false
        })
      } catch {
        this.loading = false
      }
    }
  },
  mounted() {
    this.originalItem = Object.assign({},this.item)
  }

};
</script>