<template>
  <el-table :data="items" border style="width: 100%">
    <el-table-column fixed prop="date" label="Channel ID" width="150">
      <template slot-scope="scope">{{getChannelName(scope.row.channelId)}}</template>
    </el-table-column>
    <el-table-column prop="parent_asin" label="Parent ASIN" width="120"></el-table-column>
    <el-table-column prop="asin" label="(Child) ASIN" width="120"></el-table-column>
    <el-table-column prop="sku" label="SKU" width="120"></el-table-column>
    <el-table-column prop="short_description" label="Item Short Description" width="300"></el-table-column>
    <el-table-column prop="overzied" label="Overzied" width="120"></el-table-column>

    <el-table-column prop="brand" label="Brand" width="120"></el-table-column>
    <el-table-column prop="cost_per_single_unit" label="Cost Per Single Unit" width="150"></el-table-column>
    <el-table-column prop="units" label="Units" width="120"></el-table-column>
    <el-table-column prop="packaging" label="Bag/Packaging/Label/Etc." width="180"></el-table-column>
    <el-table-column prop="estimated_labour_per_unit" label="Estimated Labour Per Unit" width="180"></el-table-column>

    <el-table-column prop="shipping_cost" label="Shipping Cost" width="120"></el-table-column>
    <el-table-column prop="anticipated_sale_price" label="Anticipated Sale Price" width="150"></el-table-column>
    <el-table-column prop="total_cost" label="Item Cost" width="120"></el-table-column>
    <el-table-column prop="units_per_case_ordered" label="Units per case ordered" width="150"></el-table-column>
    <el-table-column prop="box_number" label="Box Number" width="120"></el-table-column>

    <el-table-column prop="bag_color" label="Bag Color" width="120"></el-table-column>
    <el-table-column prop="referral_fee_percentage" label="Referral Fee %" width="120"></el-table-column>
    <el-table-column prop="estimate_referral_fee" label="Est Referral Fee $" width="130"></el-table-column>
    <el-table-column prop="estimate_fba_fee" label="Est FBA Fee" width="150"></el-table-column>
    <el-table-column prop="item_status" label="Item Status" width="150"></el-table-column>
    <el-table-column prop="main_category" label="Main Category" width="150"></el-table-column>
    <el-table-column prop="sub_category" label="Sub Category" width="150"></el-table-column>
    <el-table-column prop="tag" label="Tag" width="150"></el-table-column>
    <el-table-column prop="tag_duration" label="Tag Duration" width="150"></el-table-column>
    <el-table-column fixed="right" label="Action" width="120">
      <template slot-scope="scope">
        <el-button type="primary" @click="handleEditClick(scope.row)" size="small">Edit</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
import { getChannels } from "@/services/master-item-directory.js";
export default {
  name: "DataTable",
  props: {
    items: Array,
    pagination: Object,
    loading: Boolean
  },

  data() {
    return {
      channels: []
    };
  },
  methods: {
    handleTableChange(page) {
      this.$emit("handleTableChange", page);
    },
    showModal() {},
    getChannelName(channelId) {
      let channel = this.channels.find(ch => ch.id == channelId);
      return channel ? channel.name : "";
    },
    handleEditClick(item) {
      this.$emit("editItem", item);
    }
  },
  mounted() {
    getChannels().then(res => {
      this.channels = res.data;
    });
  }
};
</script>
