<template>
  <div class="inline-block ml-2">
    <el-button type="warning" size="medium" @click="uploadListingsCost=true">Upload Listings Cost</el-button>
    <!-- <a-button type="primary" @click="uploadListingsCost=true">Upload Listings Cost</a-button> -->

    <a-modal
      title="Upload Listings Cost"
      :visible="uploadListingsCost"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-upload-dragger name="file" :file-list="fileList" :before-upload="beforeUpload">
        <p class="ant-upload-drag-icon">
          <a-icon type="inbox" />
        </p>
        <p class="ant-upload-text">Click or drag file to this area to upload</p>
        <p class="ant-upload-hint">Support for a single upload.</p>
      </a-upload-dragger>
    </a-modal>
  </div>
</template>
<script>
import { uploadFile } from "@/services/upload.js";
export default {
  data() {
    return {
      uploadListingsCost: false,
      confirmLoading: false,
      responseMessage: "",
      fileList: [],
      file: null
    };
  },
  methods: {
    beforeUpload(file) {
      this.fileList = [file];
      this.file = file
      return false;
    },
    handleOk() {
      this.confirmLoading = true;
      this.submitFile();
    },
    handleCancel() {
      this.uploadListingsCost = false;
      this.confirmLoading = false;
      this.fileList = [];
      this.file = null
    },

    async submitFile() {
      const formData = new FormData();
      formData.append("file", this.file);
      let res = await uploadFile(formData)
      if (res) {
        this.responseMessage = "File Uploaded successfully";
        this.confirmLoading = false;
        this.uploadListingsCost = false;
      } else {
        this.confirmLoading = false;
        this.responseMessage = e.message;
      }
    }
  }
};
</script>
<style scoped>
a.btn-warning {
  color: #fff !important;
}
</style>