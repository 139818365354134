import axios from "axios";


export async function getMasterItemDirectory(params) {
  return await axios.get(`${process.env.VUE_APP_API_URL}/api/master-item-directory`, { params: params });
}

export async function getChannels() {
  return await axios.get(`${process.env.VUE_APP_API_URL}/api/channels`);
}

export async function saveItem(id,data) {
  return await axios.put(`${process.env.VUE_APP_API_URL}/api/master-item-directory/${id}`, data);
}